import React from 'react';
import styled from '@emotion/styled';
import Breadcrumb, { IPreviousRoute } from '../_Molecules/Breadcrumb';
import Title from '../_Atoms/Title';

const Container = styled.div<{ noMargin: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '16px')};
`;

const HeaderContent = styled.div`
  display: flex;
  /* align-items: center; */
  gap: 16px;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #007bff;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

interface NavigationHeaderProps {
  previousRoutes: IPreviousRoute[];
  currentRouteName: string;
  title: string;
  description?: string;
  onBackClick?: () => void;
  noMargin?: boolean;
  confirmationModal?: boolean;
  modalText?: {
    message: string;
    title: string;
  };
  actionBeforeRedirect?: () => void;
}

const NavigationHeader: React.FC<NavigationHeaderProps> = ({
  previousRoutes,
  currentRouteName,
  title,
  description,
  onBackClick,
  noMargin = false,
  confirmationModal = false,
  modalText,
  actionBeforeRedirect
}) => {
  return (
    <Container noMargin={noMargin}>
      <Breadcrumb
        previousRoutes={previousRoutes}
        currentRouteName={currentRouteName}
        noMargin={noMargin}
        confirmationModal={confirmationModal}
        modalText={modalText}
        actionBeforeRedirect={actionBeforeRedirect}
      />

      <HeaderContent>
        {onBackClick && (
          <BackButton onClick={onBackClick}>&larr; Voltar</BackButton>
        )}
        <div>
          <Title fontSize="big">{title}</Title>
          {description && (
            <p style={{ margin: 0, fontSize: '14px', color: '#6c757d' }}>
              {description}
            </p>
          )}
        </div>
      </HeaderContent>
    </Container>
  );
};

export default NavigationHeader;
