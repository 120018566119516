import axios from 'axios';

import { post } from './axios.service';
import { getToken, isValidToken } from './jwt.service';

import { API } from '../constants/api.constants';
import { buildBearer } from '../utils/format.utils';

interface IResponseRefreshToken {
  token: string;
  tokenInvalido: boolean;
}

const isLoginRequest = (url: string): boolean => {
  return url.search(/auth/i) > -1;
};

const isGoApiRequest = (url: string): boolean => {
  const baseURLGo = process.env.REACT_APP_BASE_URL_GO || '';
  return url.startsWith(baseURLGo);
};

const getGoToken = () => {
  const data: any = JSON.parse(
    window.localStorage.getItem('user_data_go') || '{}'
  );

  return data.access_token ?? null;
};

// TODO integrate refresh token before requesting to some endpoint
export const getRefreshToken = async (
  token: string
): Promise<IResponseRefreshToken> => {
  try {
    const request: IResponseRefreshToken = await post(API.AUTH.REFRESH, {
      token: buildBearer(token)
    });
    return request;
  } catch {
    return {
      token: '',
      tokenInvalido: true
    };
  }
};

axios.interceptors.request.use(
  (config) => {
    const token = isGoApiRequest(config.url || '') ? getGoToken() : getToken();

    // TODO integrate with refresh token
    if (token && isValidToken(token)) {
      if (config.headers) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = buildBearer(token);
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { status, config } = error.response;

    if (status === 401 && !isLoginRequest(config.url)) {
      localStorage.clear();
      window.location.href = `${process.env.REACT_APP_APPLICATION_URL}/login`;
    }
    return Promise.reject(error);
  }
);
