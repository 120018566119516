import React, { HTMLAttributes } from 'react';
import { MultiSelect as MultiSelection } from 'primereact/multiselect';
import { ExpandMore } from '@material-ui/icons';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import './style.css';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  options: MultiSelectOption[];
  selected: number[];
  setSelected: (e: number[]) => void;
  placeholder: string;
  disabled?: boolean;
  label?: string;
  filter?: boolean;
  inputStyle?: any;
}

export interface MultiSelectOption {
  label: string;
  value: number | string;
}

const MultiSelect: React.FC<IProps> = ({
  options,
  selected,
  setSelected,
  placeholder,
  disabled = false,
  label,
  inputStyle,
  filter = false,
  ...props
}) => {
  return (
    <div {...props}>
      <p>{label}</p>
      <MultiSelection
        value={selected}
        options={options}
        onChange={(e) => setSelected(e.value)}
        dropdownIcon={<ExpandMore />}
        showClear
        filter={filter}
        placeholder={placeholder}
        display="comma"
        disabled={disabled}
        style={inputStyle}
      />
    </div>
  );
};

export default MultiSelect;
