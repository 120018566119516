export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  FORGET_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  MAIN_MENU: '/menu'
};

export const MENU_ROUTES = {
  USER_MANAGEMENT: `${ROUTES.MAIN_MENU}/user-management`,
  CAMPAIGNS_MANAGEMENT: `${ROUTES.MAIN_MENU}/campaigns-management`,
  PROPOSALS_MANAGEMENT: `${ROUTES.MAIN_MENU}/proposals-management`,
  MESSAGES_CENTER: `${ROUTES.MAIN_MENU}/messages-center`,
  KANBAN: `${ROUTES.MAIN_MENU}/kanban`,
  ASSET_MANAGEMENT: `${ROUTES.MAIN_MENU}/asset-management`,
  TASK_LIST_MANAGEMENT: `${ROUTES.MAIN_MENU}/task-list-management`,
  LEADS_MANAGEMENT: `${ROUTES.MAIN_MENU}/leads-management`
};

export const SUB_MENU_ROUTES = {
  ADD_USER: `${MENU_ROUTES.USER_MANAGEMENT}/add-user`,
  EDIT_USER: `${MENU_ROUTES.USER_MANAGEMENT}/edit-user`,
  EDIT_USER_BY_ID: `${MENU_ROUTES.USER_MANAGEMENT}/edit-user/:id`,
  MCCS_GROUPS: `${MENU_ROUTES.CAMPAIGNS_MANAGEMENT}/mccs-groups`,
  RATE_PLANS: `${MENU_ROUTES.CAMPAIGNS_MANAGEMENT}/rate-plans`,
  OFFERS: `${MENU_ROUTES.CAMPAIGNS_MANAGEMENT}/offers`,
  CAMPAIGNS: `${MENU_ROUTES.CAMPAIGNS_MANAGEMENT}/campaigns`,
  MACHINES_PRICES: `${MENU_ROUTES.CAMPAIGNS_MANAGEMENT}/machine-prices`,
  SEND_MESSAGE: `${MENU_ROUTES.MESSAGES_CENTER}/send-message`,
  MESSAGE_HISTORY: `${MENU_ROUTES.MESSAGES_CENTER}/message-history`,
  PROPOSALS: `${MENU_ROUTES.PROPOSALS_MANAGEMENT}/proposals`,
  EXTRACT_REPORT: `${MENU_ROUTES.PROPOSALS_MANAGEMENT}/extract-report`,
  TASK_LIST: `${MENU_ROUTES.TASK_LIST_MANAGEMENT}/task-list`,
  TASK_LISK_FOLLOW_UP: `${MENU_ROUTES.TASK_LIST_MANAGEMENT}/task-list-follow-up`,
  ASSET_CONTROL: `${MENU_ROUTES.ASSET_MANAGEMENT}/asset-control`,
  MACHINE_ALLOCATION: `${MENU_ROUTES.ASSET_MANAGEMENT}/machine-allocation`,
  MACHINE_HISTORY_ALLOCATION: `${MENU_ROUTES.ASSET_MANAGEMENT}/machine-history-allocation`,
  EXCHANGE_AND_RETURN: `${MENU_ROUTES.ASSET_MANAGEMENT}/exchange-and-return`,
  HISTORY_FILES: `${MENU_ROUTES.ASSET_MANAGEMENT}/history-files`
};

export const THIRD_LEVEL_MENU_ROUTES = {
  ADD_MCC_GROUP: `${SUB_MENU_ROUTES.MCCS_GROUPS}/add`,
  EDIT_MCC_GROUP: `${SUB_MENU_ROUTES.MCCS_GROUPS}/edit`,
  EDIT_MCC_GROUP_BY_ID: `${SUB_MENU_ROUTES.MCCS_GROUPS}/edit/:id`,
  ADD_RATE_PLANS: `${SUB_MENU_ROUTES.RATE_PLANS}/add`,
  EDIT_RATE_PLANS: `${SUB_MENU_ROUTES.RATE_PLANS}/edit`,
  EDIT_RATE_PLANS_BY_ID: `${SUB_MENU_ROUTES.RATE_PLANS}/edit/:id`,
  ADD_OFFER: `${SUB_MENU_ROUTES.OFFERS}/add`,
  EDIT_OFFER: `${SUB_MENU_ROUTES.OFFERS}/edit`,
  EDIT_OFFER_BY_ID: `${SUB_MENU_ROUTES.OFFERS}/edit/:id`,
  MESSAGE_BY_ID: `${SUB_MENU_ROUTES.MESSAGE_HISTORY}/:id`,
  ADD_MACHINE_PRICES: `${SUB_MENU_ROUTES.MACHINES_PRICES}/add`,
  EDIT_MACHINE_PRICES: `${SUB_MENU_ROUTES.MACHINES_PRICES}/edit`,
  EDIT_MACHINE_PRICES_BY_ID: `${SUB_MENU_ROUTES.MACHINES_PRICES}/edit/:id`,
  APPROVE_BY_ID: `${MENU_ROUTES.PROPOSALS_MANAGEMENT}/:id/:type/`,
  EXCHANGE_AND_RETURN_SOLICITATIONS: `${SUB_MENU_ROUTES.EXCHANGE_AND_RETURN}/solicitations`,
  EXCHANGE_AND_RETURN_SOLICITATIONS_BY_ID: `${SUB_MENU_ROUTES.EXCHANGE_AND_RETURN}/solicitations/:id`,
  EXCHANGE_AND_RETURN_REGISTER: `${SUB_MENU_ROUTES.EXCHANGE_AND_RETURN}/register`,
  EXCHANGE_AND_RETURN_EDIT_SOLICITATIONS_BY_ID: `${SUB_MENU_ROUTES.EXCHANGE_AND_RETURN}/solicitations/edit/:id`
};

export const ROUTES_WITH_FOOTER_AND_WELCOME_MESSAGE = [
  ROUTES.MAIN_MENU,
  SUB_MENU_ROUTES,
  SUB_MENU_ROUTES.MCCS_GROUPS,
  SUB_MENU_ROUTES.OFFERS,
  SUB_MENU_ROUTES.RATE_PLANS,
  MENU_ROUTES.CAMPAIGNS_MANAGEMENT,
  MENU_ROUTES.USER_MANAGEMENT,
  MENU_ROUTES.MESSAGES_CENTER,
  MENU_ROUTES.PROPOSALS_MANAGEMENT,
  SUB_MENU_ROUTES.MACHINES_PRICES,
  MENU_ROUTES.TASK_LIST_MANAGEMENT,
  MENU_ROUTES.ASSET_MANAGEMENT,
  SUB_MENU_ROUTES.EXCHANGE_AND_RETURN
];
