export const STORAGE = {
  USER_DATA: 'user_data',
  USER_DATA_GO: 'user_data_go',
  HISTORY_PAGINATION_USER_EDIT: 'history_user_page',
  HISTORY_PAGINATION_MESSAGE: 'history_message_page',
  HISTORY_PAGINATION_PLANS: 'history_plans_page',
  HISTORY_PAGINATION_OFFERS: 'history_offers_page',
  HISTORY_PAGINATION_ALCADA: 'history_alcada_page',
  HISTORY_PAGINATION_MACHINE_EXCHANGE: 'history_machine_exchange_page'
};
