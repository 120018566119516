import { BrowserRouter, Route, Switch } from 'react-router-dom';

import {
  MENU_ROUTES,
  ROUTES,
  SUB_MENU_ROUTES,
  THIRD_LEVEL_MENU_ROUTES
} from '../constants/routes.constants';

import { AuthProvider } from '../contexts/auth.context';

import { Footer } from '../components/_Molecules';
import { Header } from '../components/_Organisms';
import { CREATE_INITIAL_VALUES_PLANS } from '../constants/plans.constants';
import AddMachinePrice from '../pages/AddMachinePrice';
import AddMccGroupPage from '../pages/AddMccGroup';
import AddOfferPage from '../pages/AddOffer';
import AddRatePlansPage from '../pages/AddRatePlans';
import AddUserPage from '../pages/AddUser';
import AlcadaListPage from '../pages/AlcadaList';
import ApproveAlcadaByIdPage from '../pages/ApproveAlcadaById';
import AssetControl from '../pages/AssetControl';
import AssetManagement from '../pages/AssetManagement';
import CampaignsPage from '../pages/Campaigns';
import CampaignsManagementPage from '../pages/CampaignsManagement';
import ChangePasswordPage from '../pages/ChangePassword';
import EditMachinePrice from '../pages/EditMachinePrices';
import EditMachinePricePricesById from '../pages/EditMachinePricesById';
import EditMccsGroupByIdPage from '../pages/EditMccsGroupById';
import EditMccsGroupsPage from '../pages/EditMccsGroups';
import EditOfferByIdPage from '../pages/EditOfferById';
import EditOffersPage from '../pages/EditOffers';
import EditRatePlanByIdPage from '../pages/EditRatePlanById';
import EditRatePlansPage from '../pages/EditRatePlans';
import EditUserPage from '../pages/EditUser';
import EditUserByIdPage from '../pages/EditUserById';
import ExchangeAndReturnFormEdit from '../pages/ExchangeAndReturnFormEdit';
import ExchangeAndReturn from '../pages/ExchangeAndReturnManagement';
import ExchangeAndReturnRegister from '../pages/ExchangeAndReturnRegister';
import ExchangeAndReturnSolicitations from '../pages/ExchangeAndReturnSolicitations';
import ExchangeAndReturnSolicitationsDetails from '../pages/ExchangeAndReturnSolicitationsDetails';
import ExtractReports from '../pages/ExtractReports';
import ForgotPasswordPage from '../pages/ForgotPassword';
import HistoryFiles from '../pages/HistoryFiles';
import Kanban from '../pages/Kanban/index';
import LoginPage from '../pages/Login';
import MachineAllocation from '../pages/MachineAllocation';
import MachineHistoryAllocation from '../pages/MachineHistoryAllocation';
import MachinePricesManagement from '../pages/MachinePricesManagement';
import MainMenuPage from '../pages/MainMenu';
import MccsGroupsPage from '../pages/MccsGroups';
import MessageCenter from '../pages/MessageCenter';
import MessageHistory from '../pages/MessageHistory';
import MessageView from '../pages/MessageView';
import OffersPage from '../pages/Offers';
import ProposalsManagement from '../pages/ProposalsManagement';
import RatePlansPage from '../pages/RatePlans';
import RouteNotFoundPage from '../pages/RouteNotFound';
import SendMessage from '../pages/SendMessage';
import TaskList from '../pages/TaskList';
import TaskListFollowUp from '../pages/TaskListFollowUp';
import TaskListManagement from '../pages/TaskListManagement';
import UserManagementPage from '../pages/UserManagement';
import LeadsManagementPage from '../pages/LeadsManagement';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes: React.FC = () => {
  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <Header />
          <Switch>
            <PublicRoute path={ROUTES.LOGIN} exact>
              <LoginPage />
            </PublicRoute>
            <PrivateRoute path={ROUTES.MAIN_MENU} exact>
              <MainMenuPage />
            </PrivateRoute>
            <PublicRoute path={ROUTES.FORGET_PASSWORD} exact>
              <ForgotPasswordPage />
            </PublicRoute>
            <PublicRoute path={ROUTES.CHANGE_PASSWORD} exact>
              <ChangePasswordPage />
            </PublicRoute>
            <PrivateRoute path={MENU_ROUTES.USER_MANAGEMENT} exact>
              <UserManagementPage />
            </PrivateRoute>
            <PrivateRoute path={MENU_ROUTES.LEADS_MANAGEMENT} exact>
              <LeadsManagementPage />
            </PrivateRoute>
            <PrivateRoute path={MENU_ROUTES.CAMPAIGNS_MANAGEMENT} exact>
              <CampaignsManagementPage />
            </PrivateRoute>
            <PrivateRoute path={MENU_ROUTES.MESSAGES_CENTER} exact>
              <MessageCenter />
            </PrivateRoute>
            <PrivateRoute path={MENU_ROUTES.PROPOSALS_MANAGEMENT} exact>
              <ProposalsManagement />
            </PrivateRoute>
            <PrivateRoute path={THIRD_LEVEL_MENU_ROUTES.APPROVE_BY_ID} exact>
              <ApproveAlcadaByIdPage />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.ADD_USER} exact>
              <AddUserPage />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.EDIT_USER_BY_ID} exact>
              <EditUserByIdPage />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.EDIT_USER} exact>
              <EditUserPage />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.CAMPAIGNS} exact>
              <CampaignsPage />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.MCCS_GROUPS} exact>
              <MccsGroupsPage />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.MESSAGE_HISTORY} exact>
              <MessageHistory />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.SEND_MESSAGE} exact>
              <SendMessage />
            </PrivateRoute>
            <PrivateRoute path={THIRD_LEVEL_MENU_ROUTES.ADD_MCC_GROUP} exact>
              <AddMccGroupPage />
            </PrivateRoute>
            <PrivateRoute path={THIRD_LEVEL_MENU_ROUTES.MESSAGE_BY_ID} exact>
              <MessageView />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.RATE_PLANS} exact>
              <RatePlansPage />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.OFFERS} exact>
              <OffersPage />
            </PrivateRoute>
            <PrivateRoute path={THIRD_LEVEL_MENU_ROUTES.ADD_OFFER} exact>
              <AddOfferPage />
            </PrivateRoute>
            <PrivateRoute path={THIRD_LEVEL_MENU_ROUTES.ADD_RATE_PLANS} exact>
              <AddRatePlansPage initialValues={CREATE_INITIAL_VALUES_PLANS} />
            </PrivateRoute>
            <PrivateRoute path={THIRD_LEVEL_MENU_ROUTES.EDIT_RATE_PLANS} exact>
              <EditRatePlansPage />
            </PrivateRoute>
            <PrivateRoute
              path={THIRD_LEVEL_MENU_ROUTES.EDIT_RATE_PLANS_BY_ID}
              exact
            >
              <EditRatePlanByIdPage />
            </PrivateRoute>
            <PrivateRoute path={THIRD_LEVEL_MENU_ROUTES.EDIT_MCC_GROUP} exact>
              <EditMccsGroupsPage />
            </PrivateRoute>
            <PrivateRoute
              path={THIRD_LEVEL_MENU_ROUTES.EDIT_MCC_GROUP_BY_ID}
              exact
            >
              <EditMccsGroupByIdPage />
            </PrivateRoute>
            <PrivateRoute path={THIRD_LEVEL_MENU_ROUTES.EDIT_OFFER} exact>
              <EditOffersPage />
            </PrivateRoute>
            <PrivateRoute path={THIRD_LEVEL_MENU_ROUTES.EDIT_OFFER_BY_ID} exact>
              <EditOfferByIdPage />
            </PrivateRoute>
            <PrivateRoute path={MENU_ROUTES.KANBAN} exact>
              <Kanban />
            </PrivateRoute>
            <PrivateRoute path={MENU_ROUTES.ASSET_MANAGEMENT} exact>
              <AssetManagement />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.ASSET_CONTROL} exact>
              <AssetControl />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.MACHINES_PRICES} exact>
              <MachinePricesManagement />
            </PrivateRoute>
            <PrivateRoute
              path={THIRD_LEVEL_MENU_ROUTES.ADD_MACHINE_PRICES}
              exact
            >
              <AddMachinePrice />
            </PrivateRoute>
            <PrivateRoute
              path={THIRD_LEVEL_MENU_ROUTES.EDIT_MACHINE_PRICES}
              exact
            >
              <EditMachinePrice />
            </PrivateRoute>
            <PrivateRoute
              path={THIRD_LEVEL_MENU_ROUTES.EDIT_MACHINE_PRICES_BY_ID}
              exact
            >
              <EditMachinePricePricesById />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.PROPOSALS} exact>
              <AlcadaListPage />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.EXTRACT_REPORT} exact>
              <ExtractReports />
            </PrivateRoute>
            <PrivateRoute path={MENU_ROUTES.TASK_LIST_MANAGEMENT} exact>
              <TaskListManagement />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.TASK_LIST} exact>
              <TaskList />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.TASK_LISK_FOLLOW_UP} exact>
              <TaskListFollowUp />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.MACHINE_ALLOCATION} exact>
              <MachineAllocation />
            </PrivateRoute>
            <PrivateRoute
              path={SUB_MENU_ROUTES.MACHINE_HISTORY_ALLOCATION}
              exact
            >
              <MachineHistoryAllocation />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.EXCHANGE_AND_RETURN} exact>
              <ExchangeAndReturn />
            </PrivateRoute>
            <PrivateRoute
              path={THIRD_LEVEL_MENU_ROUTES.EXCHANGE_AND_RETURN_SOLICITATIONS}
              exact
            >
              <ExchangeAndReturnSolicitations />
            </PrivateRoute>
            <PrivateRoute
              path={THIRD_LEVEL_MENU_ROUTES.EXCHANGE_AND_RETURN_REGISTER}
              exact
            >
              <ExchangeAndReturnRegister />
            </PrivateRoute>
            <PrivateRoute path={SUB_MENU_ROUTES.HISTORY_FILES}>
              <HistoryFiles />
            </PrivateRoute>
            <PrivateRoute
              path={
                THIRD_LEVEL_MENU_ROUTES.EXCHANGE_AND_RETURN_SOLICITATIONS_BY_ID
              }
              exact
            >
              <ExchangeAndReturnSolicitationsDetails />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={
                THIRD_LEVEL_MENU_ROUTES.EXCHANGE_AND_RETURN_EDIT_SOLICITATIONS_BY_ID
              }
            >
              <ExchangeAndReturnFormEdit />
            </PrivateRoute>
            <Route path="*">
              <RouteNotFoundPage />
            </Route>
          </Switch>
          <Footer />
        </BrowserRouter>
      </AuthProvider>
    </>
  );
};

export default Routes;
